const gregorian_es = {
    name: "gregorian_en_lowercase",
    months: [
      ["Enero", "Ene"],
      ["Febrero","feb"],
      ["Marzo", "mar"],
      ["Abril", "abr"],
      ["Mayo", "may"],
      ["junio", "jun"],
      ["Julio", "jul"],
      ["Agosto", "ago"],
      ["Septiembre", "sep"],
      ["Octubre", "oct"],
      ["Noviembre", "nov"],
      ["Diciembre", "dec"],
    ],
    weekDays: [
      ["Sábado", "Sab"],
      ["Domingo", "Dom"],
      ["Lunes", "Lun"],
      ["Martes", "Mar"],
      ["Miércoles", "Mie"],
      ["Jueves", "Jue"],
      ["Viernes", "Vie"],
    ],
    digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    meridiems: [
      ["AM", "am"],
      ["PM", "pm"],
    ],
  };
  
  export default gregorian_es;