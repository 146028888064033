import React from 'react'
const Navigation = ({opciones , handleClick, resultados , dashboard , consultas}) => {
   
    //validaciones de nav para saber si es resultados consultas o dashboard

    return (
        <nav className="navigation">
            <button onClick={() => handleClick("resultados")} className={`navigation--box ${resultados} navigation--box--results`}>
                Resultados
            </button>
            <button onClick={() => handleClick("consultas")} className={`navigation--box ${consultas} navigation-box--consultas`}>
                Consultas
            </button>
            <button onClick={() => handleClick("dashboard")} className={`navigation--box ${dashboard} navigation-box--dashboard`}>
                Dashboard
            </button>
        </nav>
    )
}

export default Navigation
