import React, { useEffect, useState } from "react";
import download from "../../../assets/download.svg";
import FooterNextSvg from "../../../assets/images/FooterNextSvg";
import CheckSvg from "../../../assets/images/svg/CheckSvg";
import FooterBackSvg from "../../../assets/images/svg/FooterBackSvg";
import options from "../../../assets/rueda.svg";

const Footer = ({
  activeFooter = true,
  setQuery,
  data,
  numberPages = 1,
  numberElements,
  numberElementsFrontend,
  dataElementsStatus,
}) => {
  const [active, setactive] = useState(false);
  const [pagePrev, setPagePrev] = useState(numberPages);
  const [totalPages, setTotalPages] = useState(0);
  const [results, setResults] = useState(10);
  const handleClick = (e) => {
    e.preventDefault();
    setactive(!active);
  };
  const calculatePages = (numberElements) => {
    let total = numberElements;
    if (data.rejected === 0) {
      const rejected = dataElementsStatus.rejected;
      total = total - rejected;
    }
    if (data.approved === 0) {
      const aprroved = dataElementsStatus.approved;
      total = total - aprroved;
    }
    if (data.incomplete === 0) {
      const incomplete = dataElementsStatus.incomplete;
      total = total - incomplete;
    }
    const obj_per_page = numberElementsFrontend;
    const toNumPages = Math.ceil(total / obj_per_page);

    if (toNumPages && toNumPages !== totalPages) {
      setTotalPages(toNumPages);
    }
    if (pagePrev > totalPages && totalPages > 0) {
      setPagePrev(totalPages);
      setQuery({ ...data, page: totalPages });
    }
  };

  const handleClickPageNext = () => {
    if (totalPages > pagePrev) {
      setPagePrev(pagePrev + 1);
      setQuery({ ...data, page: pagePrev + 1 });
    }
  };
  const handleClickPageprev = () => {
    if (pagePrev > 1) {
      setPagePrev(pagePrev - 1);
      setQuery({ ...data, page: pagePrev - 1 });
    }
  };

  const handleClickResults = (length) => {
    setResults(length);
    setQuery({ ...data, elements: length });
    setactive(false);
  };

  useEffect(() => {
    calculatePages(numberElements);
  }, [numberElements, data]);

  return (
    <div
      className={`${activeFooter === false ? "footer--active" : "footer"}`}
      style={{ zIndex: active ? -1 : -2 }}
    >
      {active && (
        <div className="footer__modal">
          <div
            className="footer__closemodal"
            onClick={() => {
              setactive(false);
            }}
          />
          <div className="footer__modal--modal">
            <h1>Mostrar en bloques de</h1>
            <ul>
              <li
                onClick={() => {
                  handleClickResults(10);
                }}
              >
                10 resultados
                {results === 10 && <CheckSvg />}
              </li>
              <li
                onClick={() => {
                  handleClickResults(15);
                }}
                
                >
                15 resultados
                {results === 15 && <CheckSvg />}
              </li>
              <li
                onClick={() => {
                  handleClickResults(30);
                }}
              >
                30 resultados
                {results === 30 && <CheckSvg />}

              </li>
            </ul>
            <div className="footer__modal--close">
              <button onClick={()=>{setactive(false)}}>CANCELAR</button>
            </div>
          </div>
        </div>
      )}
      <button className="footer--resultados" onClick={handleClick}>
        <img src={options} alt="" />
      </button>
      <div className="footer--enpaginado">
        <button onClick={handleClickPageprev}>
          <FooterBackSvg />
        </button>
        <div className="footer--enpaginado--numeros">
          <p className="footer--enpaginado--numeros--izquierda">{pagePrev}</p>
          <span>|</span>
          <p className="footer--enpaginado--numeros--derecha">
            {!totalPages ? 1 : totalPages}
          </p>
        </div>
        <button onClick={handleClickPageNext}>
          <FooterNextSvg />
        </button>
      </div>
      <figure>
        <img src={download} alt="" />
      </figure>
    </div>
  );
};

export default Footer;
