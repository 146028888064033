import ListLeft from "./ListLeft";
import ListRight from "./ListRight";
import Maps from "../../../Maps";

import React from "react";
const ListExpediente = ({ validation, setMapaModal, expediente = false }) => {
  if (validation) {
    return (
      <>
        {Object.values(validation).map((data) => {
          return (
            <div
              className={` ${
                expediente && data.value.latitude
                  ? "expediente--mapa"
                  : "expediente--tabla"
              }`}
              key={data.title}
            >
              <ListLeft data={data} />
              <ListRight data={data} setModalMapa={setMapaModal} />
              {expediente && data.value.latitude && (
                <article className="proceedings--map">
                  <Maps
                    center={{
                      lat: Number(data.value.latitude),
                      lng: Number(data.value.longitude),
                    }}
                  />
                </article>
              )}
            </div>
          );
        })}
      </>
    );
  } else {
    return <p className="expediente--notfound">No encontrado</p>;
  }
};

export default ListExpediente;
