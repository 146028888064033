import GoogleMapReact from "google-map-react";
import React from "react";

const Maps = ({ center }) => {
  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
      position: { lat: center.lat, lng: center.lng },
      map,
    });
    return marker;
  };

  return (
    // Important! Always set the container height explicitly
    <main style={{ height: "100%", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY_DEV }}
        defaultCenter={center}
        defaultZoom={16}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      />
    </main>
  );
};

export default Maps;
