import "react-loading-skeleton/dist/skeleton.css";

import Cajita from "../Cajita";
import Maps from "../../../Maps";
import PropTypes from "prop-types";
import React from "react";
import Skeleton from "react-loading-skeleton";
import apiScore from "../../../../api/apiScore";
import useFetch from "../../../../hooks/useFetch";

const ScoreCredit = ({ sessionId }) => {
  const { data: dataScore, loading } = useFetch(apiScore, sessionId.session);
  const dataScoreValidation = !dataScore
    ? {
        score: { nameParameter1: "" },
        geolocation: { latitude: "" },
        photos: {},
        data: null,
      }
    : dataScore;

  const {
    score,
    geolocation = { latitude: 1 },
    photos = {},
  } = dataScoreValidation;
  if (loading) {
    return (
      <>
        <Cajita titulo={"SCORE"}>
          <Skeleton height="4rem" width="95%"></Skeleton>
          <Skeleton height="4rem" width="95%"></Skeleton>
          <Skeleton height="4rem" width="95%"></Skeleton>
          <Skeleton height="4rem" width="95%"></Skeleton>
          <Skeleton height="4rem" width="95%"></Skeleton>
          <Skeleton height="4rem" width="95%"></Skeleton>
        </Cajita>
        <Cajita titulo={"GEOLOCALIZACION"}>
          <div className="Score--geolocalizacion--skeletonMapa">
            <Skeleton width="90%" height="95%"></Skeleton>
          </div>
        </Cajita>
        <Cajita titulo={"FOTOS"}>
          <div className="Score--geolocalizacion">
            <div className="Score--geolocalizacion--skeletonImage">
              <Skeleton height="45%" width="80%"></Skeleton>
              <Skeleton height="45%" width="80%"></Skeleton>
            </div>
          </div>
        </Cajita>
      </>
    );
  }
  //si hay datos en score
  else if (!loading) {
    return (
        <main className="profile--score">
          {score.nameParameter3 ? (
            <Cajita titulo={"SCORE"}>
              <div className="Score--score">
                <h4>{score.nameParameter1}</h4>
                <p>{score.parameterValue1}</p>
                <p
                  className={
                    score.parameterResult1 === "APROBADO"
                      ? "Score--score--aceptado"
                      : "Score--score--rechazado"
                  }
                >
                  {score.parameterResult1}
                </p>
              </div>

              <div className="Score--score">
                <h4>{score.nameParameter2}</h4>
                <p>{score.parameterValue2}</p>
                <p
                  className={
                    score.parameterResult1 === "APROBADO"
                      ? "Score--score--aceptado"
                      : "Score--score--rechazado"
                  }
                >
                  {score.parameterResult1}
                </p>
              </div>
              <div className="Score--score">
                <h4>{score.nameParameter3}</h4>
                <p>{score.parameterValue3}</p>
                <p
                  className={
                    score.parameterResult1 === "APROBADO"
                      ? "Score--score--aceptado"
                      : "Score--score--rechazado"
                  }
                >
                  {score.parameterResult1}
                </p>
              </div>
              <div className="Score--score">
                <h4>{score.nameParameter4}</h4>
                <p>{score.parameterValue4}</p>
                <p
                  className={
                    score.parameterResult1 === "APROBADO"
                      ? "Score--score--aceptado"
                      : "Score--score--rechazado"
                  }
                >
                  {score.parameterResult1}
                </p>
              </div>
              <div className="Score--score">
                <h4>Resultado General: </h4>
                <h5
                  className={
                    score.parameterResult1 === "APROBADO"
                      ? "Score--score--aceptadoGeneral"
                      : "Score--score--rechazadoGeneral"
                  }
                >
                  {score.parameterResult1}
                </h5>
              </div>
            </Cajita>
          ) : (
            <Cajita titulo={"SCORE"}>
              <p className="Ine--nodata">No encontrado</p>
            </Cajita>
          )}

          {geolocation.longitude ? (
            <Cajita titulo={"GEOLOCALIZACION"}>
              <div className="Score--geolocalizacion">
                <div className="Score--geolocalizacion--mapa">
                  {/* <LoadScript googleMapsApiKey="AIzaSyCzV0LcT--pMISBwd5L2PY1I0wkbLneb-s">
                                    <Map zoom={15} center={{ lat: Number(geolocation.latitude), lng: Number(geolocation.longitude) }}></Map>
                                </LoadScript> */}
                  <div className="score__geolocalizacion--googlemaps">
                    <Maps
                      center={{
                        lat: Number(geolocation.latitude),
                        lng: Number(geolocation.longitude),
                      }}
                    />
                  </div>

                  {/* <div className="Score--geolocalizacion--data">
                                    <h4>Ip Address:</h4>
                                    <p>{dataMapa.ipAddress}</p>
                                </div>
                                <div className="Score--geolocalizacion--data">
                                    <h4>Date:</h4>
                                    <p>{dataMapa.date}{dataMapa.hora}</p>
                                </div>
                                <div className="Score--geolocalizacion--data">
                                    <h4>Plataforma:</h4>
                                    <p>{dataMapa.platform}</p>
                                </div>
                                <div className="Score--geolocalizacion--data">
                                    <h4>Browser:</h4>
                                    <p>{dataMapa.browser}</p>
                                </div> */}
                  <div className="Score--geolocalizacion--data">
                    <h4>Geolocation:</h4>
                    <p>
                      {geolocation.latitude} , {geolocation.longitude}
                    </p>
                  </div>
                </div>
              </div>
            </Cajita>
          ) : (
            <Cajita titulo={"GEOLOCALIZACION"}>
              <p>No encontrado</p>
            </Cajita>
          )}
          {photos ? (
            <Cajita titulo={"FOTOS"}>
              <div className="Score--geolocalizacion">
                <div className="Score--geolocalizacion--image">
                  {Object.values(photos).map((data, i) => {
                    return (
                      <img key={data.captureDate} src={data.urlPhoto} alt="" />
                    );
                  })}
                </div>
              </div>
            </Cajita>
          ) : (
            <Cajita titulo={"FOTOS"}>
              <p>No encontrado</p>
            </Cajita>
          )}
        </main>
    );
  }
};
ScoreCredit.propTypes = {
  sessionId: PropTypes.object.isRequired,
};

export default ScoreCredit;
