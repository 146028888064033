import React from 'react'

const Aml = () => {
    return (
        <div className='aml'>
            <p className='aml--nodata'>Servicio no encontrado</p>
        </div>
    )
}

export default Aml
