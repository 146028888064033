import React from "react";
import rotateScreens from "../../assets/girar-pantalla.svg";

const RotateScreen = ({ style = "" }) => {
  return (
    <div className="Score__landscape" style={{ style }}>
      Gira tu dispositivo para tener una mejor experiencia
      <img src={rotateScreens} alt="rotate screen" />
    </div>
  );
};

export default RotateScreen;
