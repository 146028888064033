import React from "react";
import { Link } from "react-router-dom";
import LogOutIconSvg from "../../../assets/images/svg/LogOutIconSvg";
import GoIconSvg from "../../../assets/images/svg/GoIconSvg";
import SettingsSvg from "../../../assets/images/svg/SettingsSvg";
const ConfigSesSion = ({modalActive , setModalActive}) => {
  return (
    <>
    <div className="score__user--closemodal" onClick={() => {
      setModalActive(false)
    }} />

    <div className="score__user">
      <Link to="/config">
        <div className="score__user--box score__colors">
          <SettingsSvg />
          <p>Config</p>
          <GoIconSvg />
        </div>
      </Link>
      <Link to="/config">
        <div className="score__user--box ">
          <LogOutIconSvg />
          <p>Log Out</p>
          <GoIconSvg />
        </div>
      </Link>
    </div>
    </>
  );
};

export default ConfigSesSion;
