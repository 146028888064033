import React, { useState } from "react";

import Header from "../components/Score/Header/Header";
import Navigation from "../components/Score/Navigation/Navigation";
import ResultsScore from "./ResultsScore";
import rotateScreen from "../assets/girar-pantalla.svg";
import search from "../assets/Search.svg";
import user from "../assets/user.svg";
import ConfigSesSion from "../components/Score/ConfigSesion/ConfigSession";
import '../styles/pages/score.scss'
const Score = ({
  mobile,
  configCsv,
  setConfigCsv,
  setconfigCsvDefault,
  csvConfigDefault,
}) => {
  const pages = {
    resultados: true,
    consultas: false,
    dashboard: false,
  };
  const [page, setPage] = useState(pages);
  const [footer, setFooter] = useState(true);
  const [usuario, setUsuario] = useState(false);
  const handleClick = (tipo) => {
    const paginas = {
      resultados: tipo === "resultados" ? true : false,
      consultas: tipo === "consultas" ? true : false,
      dashboard: tipo === "dashboard" ? true : false,
    };
    setPage(paginas);
  };

  const handleFooter = () => {
    setFooter(!footer);
  };
  const resultados = page.resultados && "active";
  const consultas = page.consultas && "active";
  const dashboard = page.dashboard && "active";

  return (
    <div className={`${!footer ? "score" : "activado"}`}>
      {mobile ? (
        //esto es mobile
        <>
          <div className="Mobile__intento">
            <Header handleFooter={() => handleFooter()}></Header>
            <Navigation
              resultados={resultados}
              consultas={consultas}
              dashboard={dashboard}
              handleClick={handleClick}
            ></Navigation>
            {resultados ? <ResultsScore footerActive={footer} /> : ""}
          </div>

          <div className="Score__landscape">
            Gira tu telefono para tener una mejor experiencia
            <img src={rotateScreen} alt="rotate screen" />
          </div>
        </>
      ) : (
        //esto es lo de desktop
        <div className="score__containerdesk">
          <header className="score__header">
            <div className="Header--desktop">
              <Header />
              <Navigation
                resultados={resultados}
                consultas={consultas}
                dashboard={dashboard}
                handleClick={handleClick}
              />
              <div className="score--header--buscar">
                <input type="text" name="si" id="" placeholder="Buscar" />
                <img
                  src={search}
                  className="score--header--buscar--search"
                  alt=""
                />
                <img
                  src={user}
                  className="score--header--buscar--user"
                  onClick={() => {
                    setUsuario(!usuario);
                  }}
                  alt="user.svg"
                />
                {usuario && (
                  <ConfigSesSion
                    modalActive={usuario}
                    setModalActive={setUsuario}
                  />
                )}
              </div>
            </div>
          </header>
         
            {resultados && (
              <ResultsScore
                configCsvGlobal={configCsv}
                setConfigCsvGlobal={setConfigCsv}
                setconfigCsvDefault={setconfigCsvDefault}
                csvConfigDefaultGlobal={csvConfigDefault}
              />
            )}
      
        </div>
      )}
    </div>
  );
};

export default Score;
