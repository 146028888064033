import React from "react";

const SearchIcon = ({ searchInput }) => {
  return (
    <svg
      version="1.0"
      viewBox="0 0 512.000000 512.000000"
      width="100%"
      preserveAspectRatio="xMidYMid meet"
      onClick={() => {
        searchInput();
      }}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="gray"
        stroke="none"
      >
        <path
          d="M1780 5104 c-917 -110 -1657 -855 -1764 -1775 -109 -932 438 -1816
1323 -2139 667 -244 1442 -108 1977 347 28 23 55 43 60 43 5 0 362 -353 794
-784 684 -684 790 -785 821 -791 74 -14 138 50 124 124 -6 31 -107 137 -791
821 -431 432 -784 789 -784 794 0 5 20 32 43 60 150 176 280 407 359 636 249
729 63 1534 -481 2079 -448 448 -1057 660 -1681 585z m515 -220 c656 -97 1203
-545 1436 -1177 71 -193 103 -381 103 -612 1 -233 -30 -414 -108 -628 -176
-487 -586 -897 -1073 -1073 -214 -78 -395 -109 -628 -108 -290 0 -511 49 -775
171 -296 137 -588 398 -759 676 -185 303 -272 608 -272 957 -1 357 86 663 273
964 138 223 352 436 575 575 372 231 797 319 1228 255z"
        />
      </g>
    </svg>
  );
};

export default SearchIcon;
