

import { BrowserRouter, Route, Routes } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import Config from './pages/Config'
import GeneratePdf from './pages/Pdf'
import Login from './pages/Login'
import Profile from './components/Score/Profile/Profile'
import Score from './pages/Score'
import RegisterQR from "./pages/Iframe";

const App = () => {
    
    const [width, setWidth] = useState(0)
    const widthCliente = width > 991 ? false : true;
    const [configCsvDefault, setconfigCsvDefault] = useState([]);
    const [configCsv, setConfigCsv] = useState([]);
    function displayWindowSize() {
        // Get width and height of the window excluding scrollbars
        let w = document.documentElement.clientWidth;
        setWidth(w)
    }
    useEffect(() => {
        const colores = {
            backgroundColor: "--background--color",
            letra: "--text",
            svg: "--secundario-svg",
            botones: "--botones",
            botonestxt: "--botones--text"
        }
        let local = JSON.parse(localStorage.getItem('colores'))
        if (local) {
            document.documentElement.style.setProperty(colores.backgroundColor, local.bg);
            document.documentElement.style.setProperty(colores.letra, local.letra);
            document.documentElement.style.setProperty(colores.svg, local.svg);
            document.documentElement.style.setProperty(colores.botones, local.botones);
            document.documentElement.style.setProperty(colores.textoBotones, local.textoBotones);
        }
        displayWindowSize()

    }, [width])
    // // Attaching the event listener function to window's resize event
    window.addEventListener("resize", displayWindowSize);
    
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<Login />} />
                <Route exact path="/scoring/" element={<Score 
                    width={width} 
                    mobile={widthCliente} 
                    configCsv={configCsv}
                    setConfigCsv={setConfigCsv}
                    csvConfigDefault={configCsvDefault}
                    setconfigCsvDefault={setconfigCsvDefault}
                />} />
                <Route exact path="/profile/:id" element={<Profile  mobile={widthCliente}/>} />
                <Route exact path="/config" 
                    element={<Config csvConfigGlobal={configCsv} 
                    setConfigCsv={setConfigCsv}  
                    configCsvDefault={configCsvDefault}
                />} />
                <Route exact path="/pdf" element={<GeneratePdf width={widthCliente} />} />
                <Route exact path="/iframe" element={<RegisterQR />} />

                {/* <Route path="*" element={<NotFound />}></Route> */}

            </Routes>
        </BrowserRouter>
    )
}

export default App
