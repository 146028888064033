import moment from "moment";
import React from "react";

const ListRight = ({ data, setModalMapa }) => {
  return (
    <div className="expediente--tabla--derecha">
      {data.value ? (
        data.value.address ? (
          <div className="proccedings__right">
            <p>Dirección: {data.value.address}</p>
            <p>Latitud: {data.value.latitude}</p>
            <p>Longitud: {data.value.longitude}</p>
          </div>
        )
          :
          // si hay una fecha que venga pero con el formato dd/mm/yyyy
          Date.parse(data.value) ?
            <div className="proccedings__right" >
              <p  >{moment(data.value).format('DD/MM/YYYY')}</p>
            </div>
            :
            // si no hay nada pon un guion
            (
              <div className="proccedings__right" >
                <p  >{data.value === "" ? "-" : data.value}</p>
              </div>
            )
      ) : (
        <div>
          <p> - </p>
        </div>
      )}
    </div>
  );
};

export default ListRight;
