import base64 from "base-64"

let endpointApi;
  if (document.domain === "https://app.moyoai.com") {
    endpointApi = process.env.REACT_APP_URL_HOSTPROD
  } else {
    endpointApi = process.env.REACT_APP_URL_HOSTDEV
  }
  let headers = new Headers()
  headers.set('Authorization', 'Basic ' + base64.encode(process.env.REACT_APP_USER + ':' + process.env.REACT_APP_PASSWORD))
	headers.append("Origin", "http://localhost:3000");
  
  const apiFiltersId =async () =>{
    const dataForm =
    `?token=${process.env.REACT_APP_TOKEN }`;
    try {
      const response = await fetch('https://' + endpointApi + '/api/v1/ids' + dataForm, {
        method: "GET",
        headers: headers
      })
      const data = await response.json()
      return data
    } catch (error) {
      return (error)
    }
  }
export default apiFiltersId;
// css