import React from "react";

const ConfigOptions = ({ title, icon, optionText , setActives }) => {
  return (
    <div className="configoptions" onClick={()=>setActives()}>
      <div className="configoptions--title">
        <h4>{title}</h4>
      </div>
      <div className='configoptions__option'>
        {icon}
        <p>{optionText}</p>
      </div>
    </div>
  );
};

export default ConfigOptions;
