import InputLogin from '../components/Login/InputLogin'
import React from 'react'
import RotateScreen from '../components/RotateScreen/RotateScreen'

const Login = () => {
    const year = new Date()
    const body = document.querySelector("body")
    body.style.backgroundColor = 'white'
    return (
        <>
            <div className="login">
                <main className="card">
                    <div className="card__img">
                    </div>
                    <div className="card__info">
                        <h1>Welcome</h1>
                        <p>sign in to continue</p>
                        <InputLogin />
                    </div>

                </main>
                <footer className="cardFooter">
                    <div className="cardFooter__links">
                        <a href="https://www.moyoai.com/privacidad" rel="noreferrer" target="_blank">
                            Privacy
                        </a>
                        <a href="https://www.moyoai.com/privacidad" rel="noreferrer" target="_blank">
                            Terms
                        </a>
                    </div>
                    <div className="cardFooter__moyo">
                        <p>® moyoAI {year.getFullYear()}</p>
                    </div>
                </footer>
            </div>
            <RotateScreen style={{backgroundColor: 'white'}} />

        </>

    )
}

export default Login
