import React from "react";

const ItemsCameraSvg = ({ photos }) => {
  return (
    <figure>
      <div className="tooltip">
        <svg
          aria-hidden="true"
          title="Camera"
          role="img"
          viewBox="0 0 512 512"
          data-token=""
          data-session="Tl45koJ4"
        >
          <path
            className={photos ? "svg-active" : "svg-not"}
            d="M512 144v288c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48h88l12.3-32.9c7-18.7 24.9-31.1 44.9-31.1h125.5c20 0 37.9 12.4 44.9 31.1L376 96h88c26.5 0 48 21.5 48 48zM376 288c0-66.2-53.8-120-120-120s-120 53.8-120 120 53.8 120 120 120 120-53.8 120-120zm-32 0c0 48.5-39.5 88-88 88s-88-39.5-88-88 39.5-88 88-88 88 39.5 88 88z"
          ></path>
        </svg>
        <span className="tooltiptext">Fotos</span>
      </div>
    </figure>
  );
};

export default ItemsCameraSvg;
