import React from 'react'

const Buton = ({children , className}) => {
    return (
        <div className={className ? `${className}` : ''}>
            <button>{children}</button>
        </div>
    )
}

export default Buton
