import "react-loading-skeleton/dist/skeleton.css";

import React, { useEffect, useState } from "react";

import ItemsApprovedSvg from "../../../assets/images/svg/Items/ItemsApprovedSvg";
import ItemsBlackList from "../../../assets/images/svg/Items/ItemsBlackList";
import ItemsCameraSvg from "../../../assets/images/svg/Items/ItemsCameraSvg";
import ItemsCardIdSvg from "../../../assets/images/svg/Items/ItemsCardIdSvg";
import ItemsGeolocationSvg from "../../../assets/images/svg/Items/ItemsGeolocationSvg";
import ItemsIncompleteSvg from "../../../assets/images/svg/Items/ItemsIncompleteSvg";
import ItemsRejectedSvg from "../../../assets/images/svg/Items/ItemsRejectedSvg";
import ItemsSelfieSvg from "../../../assets/images/svg/Items/ItemsSelfieSvg";
import Moment from "react-moment";
import PdfSvg from "../../../assets/images/svg/PdfSvg";
import Skeleton from "react-loading-skeleton";

const Items = ({
  data,
  skeleton,
  sessionsData,
  setIdSessionData,
  noData,
  filterDefault,
  isMobile,
  numberPages,
}) => {
  const [sessionInfo, setSessionInfo] = useState([]);
  useEffect(() => {
    if (sessionsData.length > 0) {
      const infoQueryFetch = sessionsData;
      setSessionInfo(infoQueryFetch.slice(1, infoQueryFetch.length));
    }
    if (!sessionsData) {
      setSessionInfo([]);
    }
  }, [sessionsData]);

  const handleClickSessionId = (elem) => {
    if (isMobile) {
      setIdSessionData(elem);
    } else {
      setIdSessionData(elem);
    }
  };

  //Jsx
  if (noData && !sessionsData) {
    return (
      <>
        <article className="items__nodata">
          <p>No hay resultados para este rango de fecha seleccionada.</p>
        </article>
        {data.approved === 0 && data.incomplete === 0 && data.rejected === 0 && (
          <article className="items__nodata">
            <p>Seleciona Algo.</p>
          </article>
        )}
      </>
    );
  } else if (skeleton) {
    return (
      <>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((data) => (
          <div key={data} className="items__skeleton">
            <div className="items__skeleton--button">
              <Skeleton height="100%"></Skeleton>
            </div>
            <div className="items__skeleton--date">
              <Skeleton height="100%"></Skeleton>
            </div>
            <div className="items__skeleton--info">
              <Skeleton height="100%"></Skeleton>
            </div>
          </div>
        ))}
      </>
    );
  } else if (sessionsData && !noData && !skeleton) {
    const mathPagination =
      numberPages.page * numberPages.elements - (numberPages.elements - 1);
    return (
      <>
        {sessionInfo.map((elem, index) => (
          <div className="items" key={elem.session}>
            <div
              className="items__link"
              onClick={() => {
                handleClickSessionId(elem);
              }}
            >
              <div className="items__button">
                <button>{mathPagination + index}</button>
              </div>
              <div className="items__container">
                <div className="items__date">
                  <p>
                    <Moment format="DD/MM/YY">{elem.init}</Moment>
                  </p>
                </div>
                <div className="items__name">
                  {filterDefault === "session" ? (
                    <p>{elem.session}</p>
                  ) : (
                    Object.entries(elem).map(
                      ([id_, value]) =>
                        id_ === filterDefault && (
                          <p key={id_}>{id_ === filterDefault ? value : ""}</p>
                        )
                    )
                  )}
                </div>
                <div className="items__hour">
                  <p>
                    <Moment format="HH:MM">{elem.init}</Moment>
                  </p>
                </div>
                <div className="items--svgs">
                  <figure>
                    {elem.score === "Approved" && <ItemsApprovedSvg />}
                    {elem.score === "Incomplete" && <ItemsIncompleteSvg />}
                    {elem.score === "Rejected" && <ItemsRejectedSvg />}
                  </figure>
                  <ItemsGeolocationSvg geolocation={elem.geolocation} />
                  <ItemsCameraSvg photos={elem.photos} />
                  <ItemsSelfieSvg selfie={elem.selfie} />
                  <ItemsCardIdSvg identity={elem.identity} />
                  <ItemsBlackList identity={false} />
                </div>
              </div>
            </div>
            <div className="items__pdf">
              <figure>
                <PdfSvg />
              </figure>
            </div>
          </div>
        ))}
      </>
    );
  }
};

export default Items;
