import React from "react";

const ConfigColorSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="configoptions--svg "

    >
      <g clipPath="url(#clip0_975_253)">
        <path
          d="M22 24.0001H2V20.0001H22V24.0001ZM13.06 5.19006L16.81 8.94006L7.75 18.0001H4V14.2501L13.06 5.19006ZM17.88 7.87006L14.13 4.12006L15.96 2.29006C16.35 1.90006 16.98 1.90006 17.37 2.29006L19.71 4.63006C20.1 5.02006 20.1 5.65006 19.71 6.04006L17.88 7.87006Z"
          fill="#323232"
        />
      </g>
      <defs>
        <clipPath id="clip0_975_253">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ConfigColorSvg;
