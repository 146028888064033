import React from "react";

const ItemsSelfieSvg = ({ selfie }) => {
  return (
    <figure>
      <div className="tooltip">
        <svg viewBox="0 0 20 20" title="Selfie">
          <path
            className={selfie ? "svg-active" : "svg-not"}
            d="M18 12.333V16c0 1.054-.816 1.918-1.85 1.995L16 18h-3v-2h3v-3.667h2zm-14 .934V16h3v2H4c-1.054 0-1.918-.816-1.995-1.85L2 16v-2.733h2zM11 10c1.657 0 3 1.343 3 3v1H6v-1c0-1.657 1.343-3 3-3h2zm-1-5c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2zm6-3c1.054 0 1.918.816 1.995 1.85L18 4v3h-2V4h-3V2h3zM7 2v2H4v3H2V4c0-1.054.816-1.918 1.85-1.995L4 2h3z"
          ></path>
        </svg>
        <span className="tooltiptext">Selfie</span>
      </div>
    </figure>
  );
};

export default ItemsSelfieSvg;
