import React from "react";

const FilterId = ({ filtersId = { ids: { 1: "" } }, setFiltersDefault }) => {
  
    const onhange = (e) =>{
        setFiltersDefault(e.target.value);
    }
  return (
    <>
      {filtersId && (
        <div className="filter-id">
          <select id="cars" onChange={onhange} defaultValue={`id_${filtersId.default}`}>
            {Object.entries(filtersId.ids).map(([key, value]) =>{ 
              return(
              <option value={`id_${key}`} key={key} >
                {filtersId.default === key && "# "} {value}
              </option>
            )})}
            <option value="session" key="sessionID">
              {" "}
              Session ID
            </option>
          </select>
        </div>
      ) 
      }
    </>
  );
};

export default FilterId;
