import React from "react";
import ConfigBackSvg from "../../assets/images/svg/Config/ConfigBackSvg";
const ConfigHeader = ({setActive, title}) => {
    
  return (
      <header className="cajatemas__header">
        <button onClick={setActive} className='cajatemas__header--button'>
        <ConfigBackSvg />
        </button>
        <h4>{title}</h4>
      </header>
  );
};

export default ConfigHeader;
