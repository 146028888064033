import React from "react";
import Dropdown from "../../../../assets/images/svg/Dropdown";
const BoxDropDownFilter = ({ setIdActive, text, className, children }) => {
  return (
    <div
      className={`Mostrar--bloques Mostrar__caja ${className}`}
      onClick={() => {
        setIdActive((e) => !e);
      }}
    >
      {text ? <p>{text}</p> : children ? children : ""}

      <Dropdown />
    </div>
  );
};

export default BoxDropDownFilter;
