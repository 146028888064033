import React, {  useState } from "react";
import ConfigOptions from "../components/Config/ConfigOptions";

import ConfigColorSvg from "../assets/images/svg/Config/ConfigColorSvg";
import ConfigCsvSvg from "../assets/images/svg/Config/ConfigCsvSvg";
import ConfigLanguageSvg from "../assets/images/svg/Config/ConfigLanguageSvg";
import ColorTheme from "../components/Config/ColorTheme/ColorTheme";
import { useNavigate, Navigate } from "react-router-dom";
import CsvAdvanced from "../components/Config/CsvAdvanced/CsvAdvanced";
import ConfigBackSvg from "../assets/images/svg/Config/ConfigBackSvg";
import '../styles/pages/Config.scss'
const Config = ({ csvConfigGlobal = [], setConfigCsv, configCsvDefault }) => {
  const navigate = useNavigate();
  const initialState = {
    colors: false,
    csv: false,
    language: false,
  };
  const objectKeys = csvConfigGlobal;
  const [activeModals, setActiveModals] = useState({
    colors: false,
    csv: false,
    language: false,
  });
  const { colors, csv, language } = activeModals;
  const widthCliente =
    (window.screen.width && window.innerWidth) >= 992 ? false : true;
  console.log(widthCliente);
  if (csvConfigGlobal.length !== 0 || widthCliente) {
    return (
      <main className="config">
        <aside className="config__items">
          {((widthCliente && !colors && !language && !csv) ||
            !widthCliente) && (
            <header className="config__header">
              <div className="config__header--button">
                <button
                  className="config__back"
                  onClick={() => {
                    navigate("/scoring");
                  }}
                >
                  <ConfigBackSvg />
                </button>
              </div>
              <div>
                <h2 className="config__header--title">Configuraciones</h2>
              </div>
              <span className='config__header--space'></span>
            </header>
          )}

          <main className="config__container">
            {((widthCliente && !colors && !language && !csv) ||
              !widthCliente) && (
              <div className="config__options">
                <ConfigOptions
                  title={"Estilo"}
                  optionText={"Configura los  Colores"}
                  icon={<ConfigCsvSvg />}
                  setActives={() =>
                    setActiveModals({
                      colors: true,
                      csv: false,
                      language: false,
                    })
                  }
                />
                
                {!widthCliente && (
                  <ConfigOptions
                    title={"Csv"}
                    optionText={"Configura la Exportacion de Csv"}
                    icon={<ConfigColorSvg />}
                    setActives={() =>
                      setActiveModals({
                        colors: false,
                        csv: true,
                        language: false,
                      })
                    }
                  />
                )}
                <ConfigOptions
                  title={"Lenguaje"}
                  optionText={"Selecciona tu Lenguaje"}
                  icon={<ConfigLanguageSvg />}
                  setActives={() =>
                    setActiveModals({
                      colors: false,
                      csv: false,
                      language: true,
                    })
                  }
                />
              </div>
            )}
            <main className="config__modal">
              {colors && (
                <ColorTheme setActive={() => setActiveModals(initialState)} />
              )}
              {csv && (
                <CsvAdvanced
                  setActive={() => setActiveModals(initialState)}
                  csvConfigGlobal={objectKeys}
                  setConfigCsv={setConfigCsv}
                  configCsvDefault={configCsvDefault}
                />
              )}
              {language && <h2>lenguajes</h2>}
            </main>
          </main>
        </aside>
      </main>
    );
  } else {
    return <Navigate to="/scoring" replace={true} />;
  }
};

export default Config;
