import "../styles/utilities/_modalmap.scss"

import Maps from "../components/Maps";
import PropTypes from 'prop-types'
import React from 'react'

const ModalMapa = ({ modalActive, setModalActive, geoLat, geoLong }) => {

    return (
        <main className='modalmapa'>
            <div className="modalmapa--close" onClick={() => {
                setModalActive({ ...modalActive, active: false })
            }} />
            <section className="modalmapa__mapa">
                <nav className="modalmapa__mapa--closex">
                    <h3>Location</h3>
                    <button onClick={() => {
                        setModalActive({ ...modalActive, active: false })
                    }} >
                        x
                    </button>
                </nav>
                <div className="modalmapa__mapa--container">
                    <Maps center={{ lat: geoLat, lng: geoLong }} />
                </div>
                <div className="modalmapa__mapa--done">

                    <button
                        onClick={() => {
                            setModalActive({ ...modalActive, active: false })
                        }}
                    >
                        Ok
                    </button>
                </div>
            </section>
        </main>
    )
}

ModalMapa.propTypes = {
    modalActive: PropTypes.object.isRequired,
    setModalActive: PropTypes.func.isRequired
}

export default ModalMapa