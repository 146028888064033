import Skeleton from "react-loading-skeleton";
import Cajita from "../Cajita";
import ListExpediente from "../Expediente/ListExpediente";

import Buton from "../../../../utilities/Buton";
import Modal from "../../../../utilities/Modal";

import check from "../../../../assets/Check.svg";
import crossIcon from "../../../../assets/Cross.svg";

const Identidad = ({ loading, dataIdentity }) => {
  const dataScoreValidation = !dataIdentity
    ? { urlFrontID: "", urlBackID: "" }
    : dataIdentity;
  const {
    urlFrontID,
    urlBackID,
    urlIneData,
    urlIneCardBack,
    urlIneCardFront,
    urlIneFotosTitle,
    ineSimilarity,
    ineMatched,
  } = dataScoreValidation;
  console.log(dataScoreValidation);
  if (loading) {
    return (
      <>
        <Cajita titulo={"FACEMATCH"}>
          <Skeleton height="4rem" width="95%" />
          <Skeleton height="4rem" width="95%" />
          <Skeleton height="4rem" width="95%" />
          <Skeleton height="4rem" width="95%" />
          <Skeleton height="4rem" width="95%" />
          <Skeleton height="4rem" width="95%" />
        </Cajita>
        <Cajita titulo={"INE / IFE DE MEXICO"}>
          <div className="Score--geolocalizacion--skeletonMapa">
            <Skeleton width="90%" height="95%" />
          </div>
        </Cajita>
      </>
    );
  } else if (!loading) {
    const objectData = {
      1: { title: "OCR #:", value: urlIneData.idJob },
      2: { title: "Nombre", value: urlIneData.name },
      3: { title: "Apellido Paterno:", value: urlIneData.fatherName },
      4: { title: "Apellidos Materno:", value: urlIneData.motherName },
      5: { title: "Calle y Número:", value: urlIneData.avenue },
      6: { title: "Colonia:", value: urlIneData.suburb },
      7: { title: "Código Postal:", value: urlIneData.postaleCode },
      8: { title: "Municipio / Alcaldía:", value: urlIneData.delegation },
      9: { title: "Ciudad:", value: urlIneData.city },
      10: { title: "Género:", value: urlIneData.gender },
      11: { title: "Clave de elector:", value: urlIneData.electorKey },
      12: { title: "CURP:", value: urlIneData.curp },
      13: { title: "Año de registro:", value: urlIneData.registerYear },
      14: { title: "Estado:", value: urlIneData.state },
      15: { title: "Municipio:", value: urlIneData.municipality },
      16: { title: "Sección:", value: urlIneData.section },
      17: { title: "Localidad:", value: urlIneData.locality },
      18: { title: "Emisión:", value: urlIneData.issuance },
      19: { title: "Vigencia:", value: urlIneData.validity },
      20: { title: "Layout:", value: urlIneData.layout_model },
    };

    return (
      <main className="profile--identidad">
        <Cajita titulo={"FACEMATCH"}>
          {urlFrontID.includes("https://storage.googleapis.com/") &&
          urlBackID.includes("https://storage.googleapis.com/") ? (
            <div className="facematch">
              <div className="facematch--aceptabilidad">
                <div className="facematch--aceptabilidad--coincidencia">
                  <p>Coincidencia</p>
                  {ineMatched ? (
                    <img src={check} alt="" />
                  ) : (
                    <img src={crossIcon} alt="" />
                  )}
                </div>
                <div className="facematch--aceptabilidad--semejanza">
                  <p>Semejanza</p>
                  <h5>{ineSimilarity}%</h5>
                </div>
                <p>Aceptabilidad mayor a: 70%</p>
              </div>
              <div className="facematch--imagenes">
                <img src={urlFrontID} alt="" />
                <img src={urlBackID} alt="" />
              </div>
            </div>
          ) : (
            <Buton className={"facematch--validacion"}>Generar Facematch</Buton>
          )}
          {/* <Buton>Generar Facematch</Buton> */}
        </Cajita>
        <Cajita
          titulo={urlIneFotosTitle.length > 2 ?urlIneFotosTitle : "INE"  }
        >
          <div className="Ine--image">
            <Modal>
              <img
                src={urlIneCardFront}
                className="Ine--image--img"
                alt="cedula"
              />
            </Modal>
            <Modal>
              <img
                src={urlIneCardBack}
                className="Ine--image--img"
                alt="cedula"
              />
            </Modal>
            <button className="Ine--button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="edit"
                role="img"
                viewBox="0 0 576 512"
              >
                <path
                  fill="currentColor"
                  d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z"
                />
              </svg>
            </button>
          </div>
          {urlIneData.idJob ? (
            <ListExpediente validation={objectData} expediente={false} />
          ) : (
            <p className="Ine--nodata">Sin datos</p>
          )}
        </Cajita>
      </main>
    );
  }
};

export default Identidad;
