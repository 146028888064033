import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Cajas from "../Cajas";
import ConfigHeader from "../ConfigHeader";
const initialColors = {
  bg: "#ffffff",
  letra: "#6c757d",
  svg: "#80B51C",
  botones: "#80B51C",
  textoBotones: "#ffffff",
  transparent: "#F0F8FF ",
};
const ColorTheme = ({ setActive }) => {
  const navigate = useNavigate();
  const [activador, setActivador] = useState(false);
  const colores = {
    backgroundColor: "--background--color",
    letra: "--text",
    svg: "--secundario-svg",
    botones: "--botones",
    botonestxt: "--botones--text",
  };
  const [coloresDefault, setColoresDefault] = useState(initialColors);
  const [coloresStorage, setColoreStorage] = useState(
    JSON.parse(localStorage.getItem("colores"))
  );
  useEffect(() => {
    const traerDatos = () => {
      let estilosCss = getComputedStyle(document.documentElement);
      const bg = String(
        estilosCss.getPropertyValue(colores.backgroundColor)
      ).trim();
      const letra = String(estilosCss.getPropertyValue(colores.letra)).trim();
      const svg = String(estilosCss.getPropertyValue(colores.svg)).trim();
      const botones = String(
        estilosCss.getPropertyValue(colores.botones)
      ).trim();
      const textoBotones = String(
        estilosCss.getPropertyValue(colores.botonestxt)
      ).trim();

      setColoresDefault({
        ...coloresDefault,
        bg,
        letra,
        svg,
        botones,
        textoBotones,
      });
      setColoreStorage(coloresDefault);
    };
    traerDatos();
    // eslint-disable-next-line
  }, [activador]);
  useEffect(() => {
    const json = JSON.stringify(coloresDefault);
    localStorage.setItem("colores", json);
  }, [coloresStorage]);

  const resetColors = async() => {
    localStorage.removeItem("colores");
    await setColoresDefault(initialColors);
    await setColoreStorage(initialColors);
    setActivador("reset");
    setActive()
  };
  const applyColors = async() => {
    setActivador((ac) => !ac);
  };
  document.addEventListener("keydown", function (event) {
    if (event.key === "Escape") {
      navigate("/config");
    }
  });
  return (
    <div className="cajatemas">
      <ConfigHeader title={"Colores"} setActive={setActive} />
      <div
        className={`cajatemas__main ${activador && "cajatemas__main--activo"}`}
      >
        <Cajas
          activator={activador}
          colorDefault={coloresStorage ? coloresStorage.bg : coloresDefault.bg}
          everyColorsDefault={coloresDefault}
          text={"Background"}
          classname={"cajatemas__svg--background"}
          variable={colores.backgroundColor}
          activado={activador}
          setActive={setActive}
        />
        <Cajas
          activator={activador}
          colorDefault={
            coloresStorage ? coloresStorage.letra : coloresDefault.letra
          }
          everyColorsDefault={coloresDefault}
          text={"Texto"}
          classname={"cajatemas__svg--letra"}
          variable={colores.letra}
          activado={activador}
          setActive={setActive}

        />
        <Cajas
          activator={activador}
          colorDefault={
            coloresStorage ? coloresStorage.svg : coloresDefault.svg
          }
          text={"Highlights--Iconos"}
          classname={"cajatemas__svg--iconos"}
          everyColorsDefault={coloresDefault}
          variable={colores.svg}
          activado={activador}
          setActive={setActive}

        />
        <Cajas
          activator={activador}
          colorDefault={
            coloresStorage ? coloresStorage.botones : coloresDefault.botones
          }
          text={"Botones"}
          classname={"cajatemas__svg--botones"}
          everyColorsDefault={coloresDefault}
          variable={colores.botones}
          activado={activador}
          setActive={setActive}

        />
        <Cajas
          activator={activador}
          colorDefault={
            coloresStorage
              ? coloresStorage.textoBotones
              : coloresDefault.textoBotones
          }
          text={"Texto de Botones"}
          everyColorsDefault={coloresDefault}
          classname={"cajatemas__svg--botonesLetras"}
          variable={colores.botonestxt}
          activado={activador}
          setActive={setActive}

        />
        <div className="cajatemas__buttons">
          <button className="cajatemas__buttons--reset" onClick={resetColors}>
            Reiniciar Colores
          </button>
          <button className="cajatemas__buttons--aply" onClick={()=>{applyColors() 
            
          }}>
            Aplicar Cambios
          </button>
        </div>
      </div>
    </div>
  );
};

export default ColorTheme;
