import React, { useState } from 'react'
const Cajita = ({ titulo, children, initvalue = true }) => {
    const [active, setActive] = useState(initvalue);
    const validaActive = active ? "cajita--active" : ""
    return (
        <div className={`cajita`}>
            <div className="cajita--titulos" onClick={() => setActive(!active)} >
                <h1>{titulo}</h1>
                {active ? <svg  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={`${active && "img--active box--svg"}`} >
                    <path d="M5 13V11H19V13H5Z" fill="#2E3A59" />
                </svg> : <svg className='box--svg' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 13V19H11V13H5V11H11V5H13V11H19V13H13Z" fill="#2E3A59" />
                </svg>}
            </div>
            <div className={`cajita--info ${validaActive}`}>
                <div className="cajita--active--info">
                    {children}
                </div>

            </div>
        </div>
    )
}

export default Cajita
