import React, { useState } from 'react';
const InputLogin = () => {
    const initialValues = { email: "", password: "" }
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false)
    const validate = (values) => {
        const errors = {}
        const regex = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
        if (!values.email || values.email === "") {
            errors.email = "Email is required"
        }
        else if (!values.email.includes("@")) {
            errors.email = "@ is required"
        }
        else if (!regex.test(values.email)) {
            errors.email = "This is not a valid Email format"
        }
        else if (regex.test(values.email) && values.email.length > 5) {
            console.log("todo bien");
            errors.email =""
        }
        if (!values.password) {
            errors.password = "Password is required"

        }
        else if (values.password.length < 7) {
            errors.password = "Password must be more than 8 characters"
        }
        return errors
    }
    const handelChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value })
        setFormErrors(validate(formValues))
        if (!formErrors.email) {
            let error = formErrors
            console.log(error, "email");
        }

    }
    const handelSubmit = (e) => {
        e.preventDefault()
        const submitorSetErrors =()=>{
            setFormErrors(validate(formValues))
            setIsSubmit(true)
        }
        submitorSetErrors()
    }
    // useEffect(
    //     () => {
    //         if (Object.keys(formErrors).length === 0 && isSubmit) {
    //             console.log(formValues);
    //         }
    //     }, [formErrors])
    return (
        <>
            {(Object.keys(formErrors).length === 0 && isSubmit) ?
                (<div className="message-succes">
                    signed in succesfully
                </div>) : (<span></span>)
            }

            <form onSubmit={handelSubmit} className="card__info-input">
                <input type="email" name="email" id="email" placeholder="Email" value={formValues.email} onChange={handelChange} />
                <p className="password-error">{formErrors.email}</p>
                <input type="password" name="password" id="password" placeholder="Password" value={formValues.password} onChange={handelChange} />
                <p className="password-error">{formErrors.password}</p>
                <a href='/' >¿Forgot your password?</a>
                <div className="card__info-buttons">
                    <span className="card__info-buttons-register">Sign up</span>
                    <button className="card__info-buttons-login" type="submit" >Login</button>
                </div>
            </form>
        </>
    )
}

export default InputLogin
