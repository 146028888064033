import React, { useState, useEffect } from "react";
import { HexColorPicker } from "react-colorful";

const Cajas = ({
  text,
  variable,
  colorDefault,
  everyColorsDefault,
  activator,
  setActive,
}) => {
  const [color, setColor] = useState(colorDefault);
  const [activoLocal, setActivoLocal] = useState(false);
  const coloresDefecto = Object.values(everyColorsDefault);
  const setVariable =() =>{
    let colorFix =  activator !== 'reset' ? color : colorDefault
    let colorVar = variable
    return(
        document.documentElement.style.setProperty(colorVar, colorFix)
    )
}
  useEffect(() => {
   
    setVariable()
  }, [activator]);
 
  // events
  document.addEventListener("click", function (event) {
    let validacion =
      event.target.id !== text &&
      event.target.id !== "menu" &&
      event.target.className !== "picker--default" &&
      event.target.id !== "no" &&
      event.target.className !== "react-colorful__interactive";
    if (validacion) {
      setActivoLocal(false);
    }
  });

  const handleClickLocal = () => {
    if (activoLocal) {
      setActivoLocal(false);
    } else if (!activoLocal) {
      setActivoLocal(true);
    }
  };
  const handleChange = (e) => {
    setColor(e.target.value);
  };
  const oniKeypress = (e) => {
    if (e.key === "Enter") {
      setActivoLocal(false);
    }
  };

  return (
    <>
      <div className="cajatemas--caja">
        <div className="cajatemas--contenido">
          <p>{text}</p>
          <div
            className='boxtheme__drops'
          >
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="886.000000pt"
              height="1280.000000pt"
              viewBox="0 0 886.000000 1280.000000"
              preserveAspectRatio="xMidYMid meet"
              id={text}
              onClick={() => {
                handleClickLocal();
              }}

              fill={color}
              stroke='black'
              strokeWidth='500px'
            >
              <g
                transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
                >
                <path
                  id={text}
                  d="M5159 12639 c-629 -551 -1236 -1170 -1750 -1784 -571 -684 -1036
                  -1348 -1451 -2075 -145 -254 -408 -772 -517 -1020 -960 -2182 -973 -4176 -35
                  -5811 151 -264 415 -642 519 -742 28 -27 100 -90 160 -141 1211 -1022 2797
                  -1335 4160 -823 888 333 1597 982 2041 1867 206 410 378 931 448 1350 40 239
                  46 306 52 565 24 944 -293 1806 -1064 2895 -141 198 -349 475 -709 941 -764
                  989 -1045 1407 -1318 1964 -312 635 -456 1194 -472 1825 -8 353 16 630 87 981
                  17 84 29 154 27 156 -3 2 -83 -65 -178 -148z"
                  />
              </g>
            </svg>
          </div>
        </div>
        {activoLocal && (
          <div className="cajatemas__containerpicker">
            <div className="cajatemas__picker" id="menu">
              <HexColorPicker
                id="no"
                color={color}
                onChange={(e) => {
                    setColor(e);
                }}
              />
              <div className="cajatemas__picker--input">
                <button
                  id="no"
                  style={{ backgroundColor: color ? color : colorDefault }}
                ></button>
                <input
                  id="no"
                  onKeyPress={(e) => {
                    oniKeypress(e);
                  }}
                  value={color ? color : colorDefault}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </div>
              <div className="picker--default" id="no">
                {coloresDefecto.map((item, index) => (
                  <button
                    id="no"
                    key={index}
                    onClick={() => {
                      setColor(item);
                    }}
                    style={{ backgroundColor: item, border: "1px solid black" }}
                  ></button>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Cajas;
