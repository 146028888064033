import base64 from "base-64"

var endpointApi;
  if (document.domain === "https://app.moyoai.com") {
    endpointApi = process.env.REACT_APP_URL_HOSTPROD
  } else {
    endpointApi = process.env.REACT_APP_URL_HOSTDEV
  }
  let headers = new Headers()
  headers.set('Authorization', 'Basic ' + base64.encode(process.env.REACT_APP_USER + ':' + process.env.REACT_APP_PASSWORD))
  
  const apiProfile   = async (props ={})=>{
    const{session} = props
    var dataForm =
      `?token=${process.env.REACT_APP_TOKEN }&session=${session}`;
    try {
      const response = await fetch('https://' + endpointApi + '/api/v1/profile' + dataForm,  {
        method: "GET",
        headers: headers
      })
      const data = await response.json()
      return data
    } catch (error) {
      console.error(error);
    }
   
  }

export default apiProfile