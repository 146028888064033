const apiIdentity = async (session) => {
  var myHeaders = new Headers();
  myHeaders.append("Origin", "http://localhost:3000");
  myHeaders.append(
    "Authorization",
    "Basic c2F1bGVucmlxdWV6OjliZTkxMDM0YzZjMzE4MzdjYjI4N2M0YzgwYTIwNGVmMDVmMTBjNzI3NTg2Mzg0MGQwZjhhODMwMzEzMGM0NzZkODhkMjU2NGFlOGFlODBiZWMyZTJkZmIyZTNjYmQ1YTYwYjIyODI2MTQ0M2M1YTIwNTc3MmFjNmIyOWJhNmM5"
  );

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const data = fetch(
    `https://dev02.moyoai.com/api/v1/identity?token=${process.env.REACT_APP_TOKEN}&session=` +
      session,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      // LOAD TXT
      const urlFrontID = result["face"]["photoCard"];
      const urlBackID = result["face"]["photoSelfie"];

      const urlIneData = result["ocr"]["data"];

      const urlIneCardBack = result["ocr"]["photo"]["cardBack"];
      const urlIneCardFront = result["ocr"]["photo"]["cardFront"];

      const ineSimilarity = result["face"]["similarity"];

      const ineMatched = result["face"]["matched"];

      const urlIneFotosTitle = result["ocr"]["title"];
      console.log(result);
      return {
        urlFrontID,
        urlBackID,
        urlIneData,
        urlIneCardBack,
        urlIneCardFront,
        urlIneFotosTitle,
        ineSimilarity,
        ineMatched,
      };
    })
    .catch((error) => console.log("error", error));

  return data;
};

export default apiIdentity;
