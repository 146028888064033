//funcion para hacer un string  a snake case
export const toSnakeCase = string =>{

    const toSnakeCase = (str = "") => {
        const strArr = str.split(" ");
    const snakeArr = strArr.reduce((acc, val) => {
        return acc.concat(val.toLowerCase());
    }, []);
    return snakeArr.join("_");
};
const newText = toSnakeCase(string).normalize("NFD").replace(/[\u0300-\u036f]/g, "");;
return newText
}