import React, { useEffect, useState } from "react";

import zoomIn from "../assets/Zoom-in.svg";
const Modal = ({ children }) => {
  const [activo, setActivo] = useState(false);
  const widthCliente = window.screen.width && window.innerWidth;
  useEffect(() => {
    const escFunction = (event) => {
      if (event.keyCode === 27) {
        setActivo(!activo);
      }
    };
    if (activo) {
      document.addEventListener("keydown", escFunction, false);
    }
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [activo]);
  if (widthCliente > 1025) {
    return (
      <>
        {activo ? (
          <div className="Modal">
            <div className="modal__close" onClick={() => {
                    setActivo(!activo);
                  }}/>
            <div className="Modal--content">
              <div className="Modal--content--button">
                <button
                  onClick={() => {
                    setActivo(!activo);
                  }}
                >
                  X
                </button>
              </div>
              <div className="Modal--content--image">{children}</div>
            </div>
          </div>
        ) : (
          <div
            className="Modal--cerrado"
            onClick={() => {
              setActivo(!activo);
            }}
          >
            <img src={zoomIn} alt="lupita" className="Modal--cerrado--img" />
            {children}
          </div>
        )}
      </>
    );
  } else {
    return <>{children}</>;
  }
};
export default Modal;
