import React, { useEffect, useState } from "react";

import FiltersById from "./FiltersById";
import moment from "moment";
import BoxDropDownFilter from "./BoxDropDownFilter";
import DatePicker from "react-multi-date-picker"
import gregorian_es from "../../../../utilities/config-picker-date";


const Mostrar = ({
  data,
  setQuery,
  filterDefault,
  setFiltersDefault,
  filterId = { default: {}, ids: {} },
  setFilterId,
}) => {


  const [calendarioActivado, setCalendarioActivado] = useState(false);
  const [resultsNumber, setResultsNumber] = useState(false);
  const [idActive, setIdActive] = useState(false);
  const [info, setInfo] = useState({});

  const [datesFront, setDatesFront] = useState("");
  const [showNumberRegister, setshowNumberRegister] = useState(0);
  const [filterIdFront, setfilterIdFront] = useState('');

  const handleChange = (algo) => {
    setInfo(algo);
  };
  const handleClickResults = (length) => {
    setResultsNumber(!resultsNumber); 
    if (resultsNumber) {
      setshowNumberRegister(length)
      setQuery({ ...data, elements: length });
    }

  };
  const resetModals = () => {
    setIdActive(false);
    setResultsNumber(false);
    setCalendarioActivado(false);
  };

  const validData = filterId ? filterId : "";
  const { ids, default: defaultsId } = validData;
  useEffect(() => {
    if (info.length === 2) {
      const firstRange = moment(`${info[0].year}/${info[0].month.number}/${info[0].day}`).format("YYYY/MM/DD 00:00:00");
      const lastRange = moment(`${info[1].year}/${info[1].month.number}/${info[1].day}`).format("YYYY/MM/DD 23:59:59");
      console.log(info, 'asd');
      const firstRangeFront = moment(`${info[0].year}/${info[0].month.number}/${info[0].day}`).format("DD/MM/YY");
      const lastRangeFront = moment(`${info[1].year}/${info[1].month.number}/${info[1].day}`).format("DD/MM/YY");
      setDatesFront(firstRangeFront + " / " + lastRangeFront);
      setQuery({ ...data, dateInit: firstRange, dateEnd: lastRange });
    }
  }, [info]);
  useEffect(() => {
    if(validData){
      Object.entries(validData.ids).map(([key,value])=>{
        if (filterDefault.includes(key)) {
          setfilterIdFront(value);
        }else if(filterDefault === `id_${key}`){
          setfilterIdFront(value);
        }else if(filterDefault === 'session'){
          setfilterIdFront('Session Id')
        }
      })
      }
  }, [filterDefault]);

  return (
    <>
      {calendarioActivado ||
        resultsNumber ||
        idActive ? (
          <div onClick={resetModals} className="Mostrar__closemodals" />
        ):""}
      <div className="Mostrar">
      <DatePicker
          range
          value={info}
          onChange={handleChange}
          format={"DD/MM/YY"}
          className="Mostrat--bloques"
          locale={gregorian_es}
          mobileLabels={{
            OK: "Aceptar",
            CANCEL: "Cerrar",
          }}
          render={(value, openCalendar) => {
            return (
              <BoxDropDownFilter
                setIdActive={openCalendar}
                text={datesFront ? datesFront : "Definir"}
                className="Mostrar--calendary"
              />
            );
          }}
        />
        <BoxDropDownFilter
          setIdActive={setIdActive}
          className="Mostrar--calendary"
          text={filterIdFront}
        />
        <BoxDropDownFilter 
          setIdActive={setResultsNumber} 
          text={showNumberRegister ? showNumberRegister : "Mostrar"}
          className="Mostrar--results"  
        />
      
        {resultsNumber && (
          <aside className="Mostrar__show">
            <aside className="Mostrar__show--container">
              <h1>Mostrar en bloques de</h1>
              <hr />
              <ul>
                <li
                  onClick={() => {
                    setResultsNumber(!resultsNumber);
                    handleClickResults(10);
                  }}
                >
                  10 resultados
                </li>
                <li
                  onClick={() => {
                    setResultsNumber(!resultsNumber);
                    handleClickResults(15);
                  }}
                >
                15 resultados
                </li>
                <li
                  onClick={() => {
                    setResultsNumber(!resultsNumber);
                    handleClickResults(30);
                  }}
                >
                  30 resultados
                </li>
              </ul>
            </aside>
          </aside>
        )}
        {idActive && (
          <aside className="Mostrar__id">
            <h1>Filtrar por</h1>
            <hr />
            <ul>
              <li
                onClick={() => {
                  setFiltersDefault("session");
                }}
              >
                Session ID
              </li>
              {filterId && (
                <FiltersById
                  filtersId={filterId}
                  defaultId={filterDefault}
                  setFiltersDefault={setFiltersDefault}
                />
              )}
            </ul>
          </aside>
        )}
      </div>
    </>
  );
};

export default Mostrar;
// # session id
