import { useState } from "react";
import ConfigHeader from "../ConfigHeader";
import "../../../styles/components/Config/csvadvanced/csvadvanced.scss";
import { useNavigate } from "react-router-dom";

const CsvAdvanced = ({
  setActive,
  csvConfigGlobal,
  setConfigCsv,
  configCsvDefault,
}) => {
  const widthCliente =
  (window.screen.width && window.innerWidth) >= 992 ? false : true;
  const navigate = useNavigate()
  const [csvConfigLocal, setcsvConfigLocal] = useState(csvConfigGlobal);
  const [csvConfigBefore, setCsvConfigBefore] = useState([]);
  const handleChange = (e, index) => {
    e.persist();
    console.log(e.target.value);
    setCsvConfigBefore([
      ...csvConfigBefore,
      (csvConfigLocal[index] = e.target.value),
    ]);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setConfigCsv(csvConfigLocal);
    navigate('/scoring' , {replace: true})
  };

  const handleChangeCheckbox = (e, index) => {
    e.persist();
    if (e.target.value) {
      console.log(e.target.value);
      setCsvConfigBefore([
        ...csvConfigBefore,
        ...csvConfigBefore,
        (csvConfigLocal[index] = ""),
      ]);
    } else {
      setCsvConfigBefore([
        ...csvConfigBefore,
        ...csvConfigBefore,
        (csvConfigLocal[index] = configCsvDefault[index]),
      ]);
    }
  };

  return (
    <>
      <ConfigHeader setActive={setActive} title={"Csv Avanzado"} />
      <div className="csvadvanced">
        <form onSubmit={handleSubmit} className="csvadvanced__form">
          <div className="csvadvanced__inputs">
            <div className="csvadvanced__checkbox">
              <h4 className='csvadvanced__titles'>Mostrar</h4>
              {csvConfigLocal
                ? csvConfigLocal.map((item, index) => (
                    <input
                      type="checkbox"
                      className="csvadvanced__checkbox--input"
                      key={index * 55}
                      value={item}
                      checked={item}
                      onChange={(e) => {
                        handleChangeCheckbox(e, index);
                      }}
                    />
                  ))
                : ""}
            </div>
            <div className="csvadvanced__index">
              <h4 className='csvadvanced__titles'>key_#</h4>
              {configCsvDefault &&
                configCsvDefault.map((item, index) => (
                  <input type="text" key={item} value={index + 1} disabled />
                ))}
            </div>

            <div className="csvadvanced__default">
              <h4 className='csvadvanced__titles'>Default Name</h4>

              {configCsvDefault &&
                configCsvDefault.map((item, index) => (
                  <input type="text" key={item} value={item} disabled />
                ))}
            </div>

            <div className="csvadvanced__custom">
              <h4 className='csvadvanced__titles'>Custom Name</h4>
              {csvConfigLocal
                && csvConfigLocal.map((item, index) => (
                    <div key={index} className="csvadvanced__input">
                      <input
                        onChange={(e) => {
                          handleChange(e, index);
                        }}
                        type="text"
                        className="csvadvanced__custom--input"
                        placeholder={configCsvDefault[index]}
                        value={item === configCsvDefault[index] ? "" : item}
                        key={index}
                      />
                    </div>
                  ))
                }
            </div>
          </div>
          <div className={"csvadvanced__submit"}>
            <input
              type="submit"
              value={"Aplicar Cambios"}
              className="csvadvanced__submit--button"
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default CsvAdvanced;
