import React from 'react'
import Session from '../Session/Session'
import Filters from './Filters'
import Mostrar from './Mostrar/Mostrar'

const FiltersDesktop = ({
    filters,
    fetchDates,
    setFetchDates,
    filtersId,
    setFilterId,
    sessionsData
}) => {
    return (
    <div className='filters'>
        <div className="filter--desktop">
            <Filters
                filtersId={filters}
                data={fetchDates}
                setQuery={setFetchDates}
            />
            <Mostrar
                data={fetchDates}
                setQuery={setFetchDates}
                filterDefault={!filtersId && filters ? filters.default : filtersId}
                filterId={filters}
                setFiltersDefault={setFilterId}
            />
        </div>
        <div className="session--desktop">
            {sessionsData.length > 0 ? (
                <Session
                    sesiones={sessionsData[0].total}
                    scores={sessionsData[0].approved}
                    incomplete={sessionsData[0].incomplete}
                    aprroved={sessionsData[0].approved}
                    rejected={sessionsData[0].rejected}
                    dataSessions={fetchDates}
                    incompleteClick={setFetchDates}
                />
            ) : (
                <Session sesiones="0" scores="0" incompletas="0" />
            )}
        </div>
    </div>
    )
}

export default FiltersDesktop