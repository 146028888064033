import React, { useEffect, useRef, useState } from "react";

import Filters from "../components/Score/Filters/Filters";
import Footer from "../components/Score/Footer/Footer";
import Items from "../components/Score/Items/Items";
import ItemsDesktop from "../components/Score/ItemsDesktop/ItemsDesktop";
import Mostrar from "../components/Score/Filters/Mostrar/Mostrar";
import Profile from "../components/Score/Profile/Profile";
import Session from "../components/Score/Session/Session";
import apiFiltersId from "../api/apiFiltersIds";

//api
import apiSession from "../api/apiSessions";
import useFetch from "../hooks/useFetch";
import { toSnakeCase } from "../helpers/ToSnakeCase";
import FiltersDesktop from "../components/Score/Filters/FiltersDesktop";

const ResultsScore = ({
  footerActive,
  setConfigCsvGlobal,
  configCsvGlobal = [],
  setconfigCsvDefault,
  csvConfigDefaultGlobal,
}) => {
  const objetos = {
    dateInit: "2021-06-01 00:00:00",
    dateEnd: "2022-07-31 23:59:59",
    page: 1,
    elements: 10,
    approved: 1,
    rejected: 1,
    incomplete: 1,
  };
  const olRef = useRef(null)

  // validacion de que componentes renderizamos ya que desktop y mobile varia un poco
  const widthCliente =
    (window.screen.width && window.innerWidth) >= 992 ? false : true;
  const [skeleton, setSkeleton] = useState(false);
  const [fetchDates, setFetchDates] = useState(objetos);
  const [noData, setNoData] = useState(false);
  const [sessionsData, setSessionsData] = useState([]);
  const [filtersId, setFilterId] = useState("");
  const [idSessionItem, setidSessionItem] = useState({
    session: "",
  });
  const [configCsv, setConfigCsv] = useState([{}]);
  const {
    data: filters = { default: { 1: "a" } },
    loading,
    error,
  } = useFetch(apiFiltersId);
  let filtersIdDefault = !filtersId && filters ? filters.default : filtersId;
  useEffect(() => {
    if (sessionsData.length === 2) {
      setSkeleton(true);
    }

    return () => {
      setSkeleton(false);
    };
  }, [sessionsData.length]);

  useEffect(() => {
    if (widthCliente) {
      olRef.current.scrollTo(0, 0);
      console.log('hola');
    }
    setSkeleton(true);
    apiSession(fetchDates)
      .then((data) => {
        if (data.ErrorCode === "204 No Content") {
          setNoData(true);
          setSessionsData(false);
          setSkeleton(false);
        } else {
          setSessionsData(data);
          setNoData(false);
          setSkeleton(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [fetchDates]);

  const filtros = !filtersId && filters ? filters.ids : filtersId;
  useEffect(() => {
    if (sessionsData[1] && !widthCliente) {
      const keyss = Object.keys(filtros).map((key) => {
        return `id_${key}`;
      });
      const objeto2 = {};
      Object.entries(filtros).map(([key, value]) => {
        objeto2[`id_${key}`] = value;
      });
      const dataUsers = () => {
        return sessionsData.slice(1, sessionsData.length).map((items) => {
          let objeto = {};
          Object.entries(items).map(([key, value], i) => {
            objeto[
              keyss.includes(`${key}`)
                ? toSnakeCase(objeto2[key])
                : toSnakeCase(key)
            ] = value;
          });
          return objeto;
        });
      };
      const dataUsersKeys = () => {
        return sessionsData.slice(1, sessionsData.length).map((items) => {
          let objeto = {};
          Object.entries(items).map(([key, value], i) => {
            if (configCsvGlobal[i]) {
              objeto[
                configCsvGlobal[i] === " "
                  ? csvConfigDefaultGlobal[i]
                  : configCsvGlobal[i]
              ] = !objeto[configCsvGlobal[i]] ? value : "";
            }
          });
          return objeto;
        });
      };
      const csvConfigCustom = dataUsersKeys();
      const csvConfigDefault = dataUsers();
      if (configCsvGlobal.length > 1) {
        setConfigCsv(csvConfigCustom);
      } else {
        setconfigCsvDefault(Object.keys(csvConfigDefault[0]));
        setConfigCsv(csvConfigDefault);
        setConfigCsvGlobal(Object.keys(csvConfigDefault[0]));
      }
    }
  }, [filtros, sessionsData]);
  if (widthCliente) {
    return (
      <>
        <nav className="nav__mobile">
          <Filters
            data={fetchDates}
            filtersId={filters}
            setQuery={setFetchDates}
            setFiltersDefault={setFilterId}
            widthCustomer={widthCliente}
          />
          {sessionsData.length > 0 ? (
            <Session
              sesiones={sessionsData[0].total}
              scores={sessionsData[0].approved}
              incomplete={sessionsData[0].incomplete}
              aprroved={sessionsData[0].approved}
              rejected={sessionsData[0].rejected}
              dataSessions={fetchDates}
              incompleteClick={setFetchDates}
            />
          ) : (
            <Session sesiones="0" scores="0" incompletas="0" />
          )}
        </nav>
        <div className="score--container" ref={olRef}>
          <Items
            data={fetchDates}
            noData={noData}
            skeleton={loading || skeleton}
            sessionsData={sessionsData}
            setSessionsData={setSessionsData}
            idSessionsData={idSessionItem}
            setIdSessionData={setidSessionItem}
            numberPages={fetchDates}
            filterDefault={!filtersId && filters ? filters.default : filtersId}
            filterId={filters}
          />
        </div>
        <Footer
          data={fetchDates}
          numberPages={fetchDates.page}
          numberElementsFrontend={fetchDates.elements}
          numberElements={sessionsData.length > 0 && sessionsData[0].total}
          dataElementsStatus={sessionsData.length > 0 && sessionsData[0]}
          setQuery={setFetchDates}
          activeFooter={footerActive}
        />
        {idSessionItem.session && (
          <Profile
            closeScoreMobile={setidSessionItem}
            sessionData={idSessionItem}
          />
        )}
      </>
    );
  } else {
    return (
      <>
      
        <FiltersDesktop
          filters={filters}
          fetchDates={fetchDates}
          setFetchDates={setFetchDates}
          filtersId={filtersId}
          setFilterId={setFilterId}
          sessionsData={sessionsData}
        />
        <section className="score__main">
          <ItemsDesktop
            configCsv={configCsv}
            data={fetchDates}
            skeleton={loading}
            sessionsData={sessionsData}
            noData={noData}
            dataSelect={idSessionItem}
            numberPages={fetchDates.page}
            numberElementsFrontend={fetchDates.elements}
            numberElements={sessionsData.length > 0 && sessionsData[0].total}
            dataElementsStatus={sessionsData.length > 0 && sessionsData[0]}
            setQuery={setFetchDates}
          >
            <Items
              data={fetchDates}
              noData={noData}
              skeleton={loading || skeleton}
              sessionsData={sessionsData}
              setSessionsData={setSessionsData}
              idSessionsData={idSessionItem}
              setIdSessionData={setidSessionItem}
              numberPages={fetchDates}
              filterDefault={!filtersId && filters ? filters.default : filtersId}
              filterId={filters}
            />
          </ItemsDesktop>
        </section>

      </>
    );
  }
};

export default ResultsScore;
