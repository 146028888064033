import "react-loading-skeleton/dist/skeleton.css";

import React, { useState } from "react";

import PropTypes from "prop-types";

import Aml from "./AML/Aml";
import Cajita from "./Cajita";
import CrossSvg from "../../../assets/images/svg/Cross";
import Expediente from "./Expediente/Expediente";
import Identidad from "./Identidad/Identidad";
import { Link } from "react-router-dom";
import Modal from "../../../utilities/Modal";
import ModalMapa from "../../../utilities/ModalMapa";
import PdfSvg from "../../../assets/images/svg/PdfSvg";
import RotateScreen from "../../RotateScreen/RotateScreen";
import ScoreCredit from "./Score/ScoreCredit";
import Skeleton from "react-loading-skeleton";
import apiIdentity from "../../../../src/api/apiIdentity";
import defaultImage from "../../../assets/user.svg";
import recibo from "../../../assets/recibo.jpeg";
import useFetch from "../../../hooks/useFetch";

const Profile = ({ sessionData = {}, closeScoreMobile }) => {
  const toogles = {
    Score: true,
    Identidad: false,
    Aml: false,
    Expediente: false,
  };

  const [activos, setActivos] = useState(toogles);

  const handleClick = (tipo) => {
    const paginas = {
      Score: tipo === "Score" ? true : false,
      Identidad: tipo === "Identidad" ? true : false,
      Aml: tipo === "Aml" ? true : false,
      Expediente: tipo === "Expediente" ? true : false,
    };
    const div = document.querySelector(".profile--info");
    div.scrollTop = 0;
    setActivos(paginas);
  };
  const sessionId = sessionData.session ? sessionData.session : "";
  const { data: identityData = {}, loading } = useFetch(apiIdentity, sessionId);
  const [modalMaps, setModalMaps] = useState({
    active: false,
    lat: "1",
    lng: "1",
  });
  return (
    <>
      {modalMaps.active && (
        <ModalMapa
          modalActive={modalMaps}
          setModalActive={setModalMaps}
          geoLat={modalMaps.lat}
          geoLong={modalMaps.lng}
        />
      )}
      <main className="profile">
        <div className="profile--button">
          <button
            onClick={() => {
              closeScoreMobile({
                session: null,
              });
            }}
          >
            <CrossSvg />
          </button>
        </div>

        <header className="profile--header">
          <div className="profile--header--datos">
            {loading ? (
              <>
                <h1>
                  <Skeleton width="70%" />
                </h1>
                <h1>
                  <Skeleton width="60%" />
                </h1>
                <p>
                  <Skeleton width="90%" />
                </p>
                <p>
                  <Skeleton width="80%" />
                </p>
              </>
            ) : (
              <>
                <h1>moyoAI Credit Score</h1>
                <h1>ID: {sessionData ? sessionData.id_2 : sessionData.id_2}</h1>
                <p>
                  Sesion:
                  {sessionData[1] && !sessionData.session
                    ? sessionData[1].session
                    : sessionData.session}
                </p>
                <p>
                  Fecha:
                  {sessionData[1] && !sessionData.session
                    ? sessionData[1].init
                    : sessionData.init}
                </p>
              </>
            )}
          </div>
          <div className="profile--header--buttons">
            <figure>
              <Link to="/pdf">
                <PdfSvg />
              </Link>
            </figure>
          </div>
          <div className="profile--header--image">
            {!identityData ? (
              <div>
                <Skeleton width="70%" height="110%" circle={true} />
              </div>
            ) : identityData.urlBackID.includes("https:") ? (
              <img src={identityData.urlBackID} alt="" />
            ) : (
              <img src={defaultImage} alt="image default" />
            )}
          </div>
        </header>
        <main className="profile--options">
          <nav className="profile--options--buttons">
            <button
              onClick={() => {
                handleClick("Score");
              }}
              className={`buton ${activos.Score && "active"}`}
            >
              Score
            </button>
            <button
              onClick={() => {
                handleClick("Identidad");
              }}
              className={`buton ${activos.Identidad && "active"}`}
            >
              Identidad
            </button>
            <button
              onClick={() => {
                handleClick("Aml");
              }}
              className={`buton ${activos.Aml && "active"}`}
            >
              AML / PLD
            </button>
            <button
              onClick={() => {
                handleClick("Expediente");
              }}
              className={`buton ${activos.Expediente && "active"}`}
            >
              Expediente
            </button>
          </nav>
          <div className="profile--info">
            {activos.Score && sessionData && (
              <ScoreCredit sessionId={sessionData} />
            )}
            {activos.Identidad && identityData && (
              <Identidad loading={loading} dataIdentity={identityData} />
            )}
            {activos.Aml && <Aml />}
            {activos.Expediente && sessionData && (
              <Expediente sessionId={sessionData} setModalMapa={setModalMaps} />
            )}
          </div>
        </main>
      </main>
      <RotateScreen />
    </>
  );
};
Profile.propTypes = {
  skeleton: PropTypes.bool.isRequired,
  sessionData: PropTypes.object.isRequired,
};
Profile.defaultProps = {
  skeleton: true,
  sessionData: {},
};
export default Profile;
