import DatePicker from "react-multi-date-picker";
import BoxDropDownFilter from "./Mostrar/BoxDropDownFilter";
import gregorian_es from "../../../utilities/config-picker-date";
import "react-multi-date-picker/styles/layouts/mobile.css";
export default function FilterCalendary({
  value,
  setValue,
  dateFront,
  open,
  modalClick,
}) {
  return (
    <>
      <DatePicker
        range
        value={value}
        onChange={setValue}
        format={"DD/MM/YY"}
        className="rmdp-mobile"
        locale={gregorian_es}
        mobileLabels={{
          OK: "Aceptar",
          CANCEL: "Cerrar",
        }}
        onClose={modalClick}
        render={(value, openCalendar) => {
          return (
            <>
              {open && (
                <div className="filter__offmodal" onClick={()=>{
                  openCalendar();
                  modalClick();
                }} />
              )}
              <BoxDropDownFilter
                className="filter__idDate--define"
                text={dateFront ? dateFront : "Definir"}
                setIdActive={() => {
                  openCalendar();
                  modalClick();
                }}
              />
            </>
          );
        }}
      />
    </>
  );
}
