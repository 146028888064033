import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import React, { useCallback, useEffect } from "react";

import DocuPDF from "../components/Pdf/Pdf";
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';

function GeneratePdf({ width }) {
  const history = useNavigate();

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      history('/scoring')
    }
  }, []);
  useEffect(() => {
    document.addEventListener("keydown", escFunction);
    return () => {
      document.removeEventListener("keydown", escFunction);
    };
  }, [escFunction]);

  return (
    <div style={{ minHeight: "100vh" }}>
      <nav className="pdf__nav">
        <Link to='/scoring'>
          <svg width="24" height="24" className="pdf__nav--button" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.46445 15.5354L15.5355 8.46436" stroke="gray" />
            <path d="M8.46446 8.46458L15.5355 15.5356" stroke="gray" />
          </svg>

        </Link>
      </nav>
      {width ?
         <main className="pdf__mobile">

           <PDFDownloadLink fileName="score--tunombre.pdf" document={ <DocuPDF />} > 
           <button className="pdf__mobile--button" >
              Descargar Pdf
           </button>
           </PDFDownloadLink>
           <p className="pdf__mobile--text">score--tunombre.pdf</p>
         </main>
        :
        <PDFViewer style={{ width: "100%", height: "93vh" }}>
          <DocuPDF />
        </PDFViewer>

      }


    </div>
  );
}

export default GeneratePdf;
