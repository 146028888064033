import React from 'react'

const ListLeft = ({ data }) => {
    return (
        <div className="expediente--tabla--izquierda">
            <div >
                <p>{data.title}</p>
            </div>
        </div>
    )
}

export default ListLeft