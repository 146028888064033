import PropTypes from "prop-types";
const FiltersById = ({  filtersId ={ ids:{ 1: "" },}, setFiltersDefault }) => {
  console.log(filtersId);
    return (
    <>
      {filtersId &&
        Object.entries(filtersId.ids).map(([key,value]) => {
          return(
        
              <li key={value} onClick={()=>setFiltersDefault(`id_${key}`)}>{value}</li>
        )})}
    </>
  );
};
FiltersById.propTypes = {
  filtersId: PropTypes.object.isRequired,
};
export default FiltersById;
