import Footer from "../Footer/Footer";
import Profile from "../Profile/Profile";
import { useEffect, useRef, useState } from "react";
import { useCSVDownloader } from "react-papaparse";

const ItemsDesktop = ({
  data,
  setQuery,
  dataElementsStatus,
  noData,
  numberPages = 0,
  numberElements = 0,
  numberElementsFrontend,
  children,
  sessionsData,
  dataSelect,
  configCsv,
}) => {
  const { CSVDownloader, Type } = useCSVDownloader();
  const [dataCsv, setDataCsv] = useState([{}]);
  const olRef = useRef(null);

  useEffect(() => {
    if (sessionsData.length > 1) {
      const dataUsers = sessionsData.slice(1, sessionsData.length);
      const arraysito = [];
      dataUsers.map((item) => {
        arraysito.push({
          session: item.session,
          name: item.id_2,
          geolocation: item.geolocation,
          init: item.init,
          ine: item.id_9,
        });
      });
      setDataCsv(arraysito);
      olRef.current.scrollTo(0, 0);
    }
  }, [sessionsData]);
  return (
    <main className="ScoreDesktop">
      <aside className="ScoreDesktop--lista">
        <ol ref={olRef}>{children}</ol>
        {dataCsv.length > 1 && (
          <div className="ScoreDesktop__footer">
            <Footer
              data={data}
              numberPages={numberPages}
              numberElements={numberElements}
              numberElementsFrontend={numberElementsFrontend}
              setQuery={setQuery}
              dataElementsStatus={dataElementsStatus}
              noResults={noData}
            />
            {configCsv && (
              <div className="export">
                <CSVDownloader
                  className="export__button"
                  type={Type.Div}
                  filename={"moyoaiCsv"}
                  bom={true}
                  config={{
                    delimiter: ";",
                  }}
                  data={configCsv}
                >
                  <button>Exportar</button>
                </CSVDownloader>
              </div>
            )}
          </div>
        )}
      </aside>
      <aside className="ScoreDesktop--profile">
        {dataSelect.session ? (
          <Profile sessionData={dataSelect} />
        ) : (
          <h2 className="score__pickresult">Selecciona un Registro</h2>
        )}
      </aside>
    </main>
  );
};

export default ItemsDesktop;
