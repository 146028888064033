import React, { useState } from "react";

import Cajita from "../Cajita";
import ListExpediente from "./ListExpediente";
import Skeleton from "react-loading-skeleton";
import apiProfile from "../../../../api/apiProfile";
import useFetch from "../../../../hooks/useFetch";
import Modal from "../../../../utilities/Modal";

const Expediente = ({ sessionId = "", children, setModalMapa }) => {
  const { data, loading } = useFetch(apiProfile, sessionId);
  const profileData = data ? data : null;
  const validation = profileData ? profileData.profile : false;
  const validateDocs = profileData ? profileData.docs : false
  return (
    <>
      {loading ? (
        <>
          <Cajita titulo={"Otros Documentos"}>
            <Skeleton height="4rem" width="95%"></Skeleton>
            <Skeleton height="4rem" width="95%"></Skeleton>
            <Skeleton height="4rem" width="95%"></Skeleton>
            <Skeleton height="4rem" width="95%"></Skeleton>
            <Skeleton height="4rem" width="95%"></Skeleton>
            <Skeleton height="4rem" width="95%"></Skeleton>
            <Skeleton height="4rem" width="95%"></Skeleton>
            <Skeleton height="4rem" width="95%"></Skeleton>
            <Skeleton height="4rem" width="95%"></Skeleton>
            <Skeleton height="4rem" width="95%"></Skeleton>
            <Skeleton height="4rem" width="95%"></Skeleton>
            <Skeleton height="4rem" width="95%"></Skeleton>
          </Cajita>
        </>
      ) : (
        <div className="expediente">
          <div className="expediente--tablas">
            <ListExpediente
              expediente={true}
              setMapaModal={setModalMapa}
              validation={validation}
            />
          </div>
          <div className="expediente--cajita">
            {validateDocs &&
              profileData.docs.map((img) => (

                <Cajita key={img.title} titulo="Otros documentos" initvalue={false}>
                  <div className="expediente--documentos">
                    <Modal>
                      <img
                        src={ img.value}
                        className="expediente--documentos--img"
                        alt="recibo"
                      />
                    </Modal>
                  </div>
                </Cajita>
              ))
            }
          </div>
        </div>
      )}
    </>
  );
};
export default Expediente;
