import React from "react";
const RegisterQR = () => {
  return (
    <div style={{height: '90vh'}}>
      <iframe
        title="goRegister"
        id="typeform-full"
        width="100%"
        height="100%"
        frameBorder="0"
        src="https://go.moyoai.com/Au22BKe5"
        allow="geolocation; camera"
      ></iframe>
    </div>
  );
};

export default RegisterQR;
