import React, { useEffect, useState } from "react";

import FilterCalendary from "./FilterCalendary";
import FilterId from "./FilterId";
import moment from "moment";

const Filters = ({
  setQuery,
  data,
  filtersId = { default: "a" },
  setFiltersDefault,
  widthCustomer,
}) => {

  const [calendaryOn, setCalendaryOn] = useState(false);
  const [info, setInfo] = useState([]);
  const [datesFront, setDatesFront] = useState("");
  const handleChange = (algo) => {
    setInfo(algo);
  };
  const handleClickWeek = () => {
    const startWeek = moment().startOf("isoweek").format("YYYY-MM-DD HH:mm:ss");
    const endWeek = moment().endOf("isoweek").format("YYYY-MM-DD HH:mm:ss");
    setQuery({ ...data, dateInit: startWeek, dateEnd: endWeek });
  };
  const handleClickMonth = () => {
    const startOfMonth = moment().startOf("month").format("YYYY-MM-DD h:mm:ss");
    const endOfDay = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");
    setQuery({ ...data, dateInit: startOfMonth, dateEnd: endOfDay });
  };

  const handleClickToday = () => {
    const startOfDay = moment().startOf("day").format("YYYY-MM-DD HH:mm:ss");
    const endOfDay = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");

    setQuery({ ...data, dateInit: startOfDay, dateEnd: endOfDay });
  };
  useEffect(() => {
    if (info.length === 2) {
      const firstRange = moment(
        `${info[0].year}/${info[0].month.number}/${info[0].day}`
      ).format("YYYY/MM/DD 00:00:00");
      const lastRange = moment(
        `${info[1].year}/${info[1].month.number}/${info[1].day}`
      ).format("YYYY/MM/DD 23:59:59");
      console.log(firstRange, lastRange);
      const firstRangeFront = moment(
        `${info[0].year}/${info[0].month.number}/${info[0].day}`
      ).format("DD/MM/YY");
      const lastRangeFront = moment(
        `${info[1].year}/${info[1].month.number}/${info[1].day}`
      ).format("DD/MM/YY");
      setDatesFront(firstRangeFront + " - " + lastRangeFront);
      setQuery({ ...data, dateInit: firstRange, dateEnd: lastRange });
    }
  }, [info]);
  return (
    <>
      <nav className="filter">
        <div className="filter--date">
          <div
            onClick={handleClickToday}
            className="filter--box filter--box--hoy"
          >
            <p>Hoy </p>
          </div>
          <div
            onClick={handleClickWeek}
            className=" filter--box filter--box--consultas"
          >
            <p>Semana</p>
          </div>
          <div
            onClick={handleClickMonth}
            className=" filter--box filter--box--mes"
          >
            <p>Mes</p>
          </div>
        </div>
      </nav>
      {widthCustomer && (
        <div className="filter__idDate">
          <FilterId
            filtersId={filtersId}
            setFiltersDefault={setFiltersDefault}
          />
          <FilterCalendary
            dateFront={datesFront}
            open={calendaryOn}
            value={info}
            setValue={handleChange}
            className="calendary"
            modalClick={()=>{setCalendaryOn(!calendaryOn)}}
          />
        </div>
      )}
    </>
  );
};

export default Filters;
