import React, { useState ,memo } from 'react'

const Session = ({
    sesiones = 0,
    scores = 0,
    incomplete = 0,
    aprroved = 0,
    rejected = 0,
    incompleteClick,
    dataSessions,
}) => {
    const widthCustomer = window.screen.width > 576 ? true : false;
    const [aprrovedToogle, setAprrovedToogle] = useState(true);
    const [rejectedToogle, setRejectedToogle] = useState(true);
    const [incompletedToogle, setIncompletedToogle] = useState(true);
    const [noChanges, setnoChanges] = useState(false);
    const [toogle, setToogle] = useState(false);
    const handleClickCheckBox = async (e) => {
        if (dataSessions && e.value === 'incomplete' && !noChanges) {
            setIncompletedToogle(!incompletedToogle)
            incompleteClick({
                ...dataSessions,
                incomplete: incompletedToogle ? 0 : 1,
                approved: aprrovedToogle ? 1 : 0,
                rejected: rejectedToogle ? 1 : 0,
            })
            console.log("incomplete");
        }
        else if (dataSessions && e.value === 'approved' && !noChanges) {
            setAprrovedToogle(!aprrovedToogle)
            incompleteClick({
                ...dataSessions,
                approved: aprrovedToogle ? 0 : 1,
                rejected: rejectedToogle ? 1 : 0,
                incomplete: incompletedToogle ? 1 : 0
            })
            console.log("approved");
        }
        else if (dataSessions && e.value === 'rejected' && !noChanges) {
            setRejectedToogle(!rejectedToogle)
            incompleteClick({
                ...dataSessions,
                rejected: rejectedToogle ? 0 : 1,
                approved: aprrovedToogle ? 1 : 0,
                incomplete: incompletedToogle ? 1 : 0
            })
        }
    }
    const handleClickToogleSessions = () => {
        setToogle(!toogle)
    }
    return (
        <div className="session">
            <div className="session__box session__sesiones">
                <p className='session__box--titulo'> Sesiones</p>
                <p className='session__box--contenido'>{sesiones}</p>
            </div>
            <div className="session__box session__scores" onClick={handleClickToogleSessions}>
                <p className='session__box--titulo'> Scores</p>
                <p className='session__box--contenido'>{scores}</p>
            </div>
            {!widthCustomer ?
                <div className="session__box session__input session__incompleted">
                    <div className="session__input--checkbox">
                        <input
                            defaultChecked={incompletedToogle}
                            type="checkbox"
                            value={'incomplete'}
                            name="incompletos"
                            onChange={(e) => { handleClickCheckBox(e.target) }}
                        />
                        <p className='session__box--titulo'>Incompletas</p>
                    </div>
                    <p className='session__box--contenido'>{incomplete}</p>
                </div>

                :

                <div className="session__box session__input">
                    <div className="session__input--checkbox">
                        <input
                            defaultChecked={aprrovedToogle}
                            type="checkbox"
                            value={'approved'}
                            name="incompletos"
                            onChange={(e) => { handleClickCheckBox(e.target) }}
                        />
                        <p className='session__box--titulo'>Aprobados</p>
                    </div>
                    <p className='session__box--contenido'>{aprroved}</p>
                </div>
            }


            {widthCustomer || toogle ?
                <>
                    {toogle ?
                        <div className='session__outelement' onClick={handleClickToogleSessions} />
                        :"" 
                    }

                    <div className="session__filters">

                        <div className="session__box session__input session__rejected" >
                            <div className="session__input--checkbox">
                                <input
                                    defaultChecked={rejectedToogle}

                                    type="checkbox"
                                    value={'rejected'}
                                    name="rejected"
                                    onChange={(e) => { handleClickCheckBox(e.target) }}
                                />
                                <p className='session__box--titulo'>Rechazados</p>
                            </div>
                            <p className='session__box--contenido'>{rejected}</p>
                        </div>
                        {widthCustomer ?
                            <div className="session__box session__input session__incompleted">
                                <div className="session__input--checkbox">
                                    <input
                                        defaultChecked={incompletedToogle}
                                        type="checkbox"
                                        value={'incomplete'}
                                        name="incompletos"
                                        onChange={(e) => { handleClickCheckBox(e.target) }}
                                    />
                                    <p className='session__box--titulo'>Incompletas</p>
                                </div>
                                <p className='session__box--contenido'>{incomplete}</p>
                            </div>

                            :

                            <div className="session__box session__input">
                                <div className="session__input--checkbox">
                                    <input
                                        defaultChecked={aprrovedToogle}
                                        type="checkbox"
                                        value={'approved'}
                                        name="incompletos"
                                        onChange={(e) => { handleClickCheckBox(e.target) }}
                                    />
                                    <p className='session__box--titulo'>Aprobados</p>
                                </div>
                                <p className='session__box--contenido'>{aprroved}</p>
                            </div>
                        }
                    </div>


                </>

                : ""
            }


        </div>
    )
}

export default Session
